.p2p {

    // .p2p__buy

    &__buy {
        background: #42B955;
        color: #fff!important;
    }

    // .p2p__btn-primary

    &__btn-primary {
        padding: 10px 20px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        font-weight: 500;
        font-size: 20px;
        color: #000;
        line-height: 125%;
        letter-spacing: 0.02em;
        border: none;
        transition: all 0.3s ease 0s;
        width: 100%;
        white-space: nowrap;

        @media (max-width: 639.97px) {
            padding: 5px 20px;
            font-size: 12px;
        }

        &:hover {
            filter: brightness(95%);
            cursor: pointer;
        }
    }

    // .p2p__btn-secondary

    &__btn-secondary {
        padding: 10px 24px;
        background: #FFFFFF;
        border: 2px solid #E8E9EB;
        border-radius: 12px;
        font-weight: 500;
        font-size: 20px;
        line-height: 125%;
        letter-spacing: 0.01em;
        color: #898A9E;
        transition: all 0.3s ease 0s;
        width: 100%;
        white-space: nowrap;

        @media (max-width: 639.97px) {
            padding: 6px 12px;
            font-size: 12px;
            border: 1px solid #E8E9EB;
        }

        &:hover {
            filter: brightness(95%);
            cursor: pointer;
        }
    }

    // .p2p__default

    &__default {
        background-color: #DA932C;
        color: #fff;
    }

    // .p2p__btn-active-primary

    &__btn-active-primary {}

    // .p2p__btn-active-secondary

    &__btn-active-secondary {
        border: 2px solid #F37032;
        border-radius: 12px;
        color: #F37032;

        @media (max-width: 639.97px) {
            border: 1px solid #F37032;
        }
    }

    // .p2p__sell

    &__sell {
        background: #F14747;
        color: #fff!important;
    }
}

.p2p-btn-not-active {
    border: none;
    color: #898A9E;
    background-color: transparent;
    box-shadow: none;
}

.auth {

  // .auth__wrapper

  &__wrapper {
      width: 100vw;
      height: 100vh;
      background-color: #000211;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url('../../assets/images/bg.png');
      background-size: cover;
      background-repeat: no-repeat;
  }

  // .auth__header

  &__header {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      user-select: none;
      position: absolute;
      top: 26px;
      right: 32px;
  }

  // .auth__content

  &__content-wrapper {
    padding: 32px 32px 40px;
    width: 426px;
    background: #0A0C1B;
    border: 1px solid #333D4E;
    border-radius: 25px;

      @media (max-width: 639.97px) {
          width: 320px;
      }
  }

  // .auth__content

  &__content {
      display: flex;
      flex-direction: column;
      gap: 24px;
  }

  // .auth__btn-wrapper

  &__btn-wrapper {
      margin: 8px auto 0px auto;
  }

  // .auth__error

  &__error {
      color: #fff;
  }
}

.UIInput {
    .UIInput-input {
        padding: 12px;
        min-height: 44px;
        min-width: 250px;
        transition: .2s ease;
        border-radius: 8px;
        background: #141625;
        box-shadow: 0px 0px 0px 1px #E8E9EB;
        border-radius: 8px;
        border: 0;
        -webkit-appearance: none;
        font-size: 16px;
        color: #fff;
        width: 100%;

        @media (max-width: 639px) {
            padding: 8px;
            min-height: 26px;
            height: 26px;
            font-size: 14px;
        }

        &._valid {
            border: 1px solid #42B955;
        }

        &._invalid {
            border: 1px solid #42B955;
        }

        &::placeholder {
            color: #ADAEB1;
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            letter-spacing: 0.02em;
        }
    }

    .UIInput-wraper {
        display: flex;
        align-items: center;
        position: relative;
    }

    .UIInput-symbol {
        min-height: 22px;
        position: absolute;
        right: 10px;
        font-size: 14px;
        min-width: fit-content;
        padding: 5px;
        background-color: #fff;
        max-width: 20%;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.02em;
        color: #898A9E;
    }

    .UIInput-label {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.02em;
        color: #898A9E;

        @media (max-width: 639px) {
            font-size: 12px;
            line-height: 140%;
        }
    }

    .UIInput-error {
        color: rgb(255, 68, 0);
        position: absolute;
        top: 105%;
        left: 0;
        font-size: 14px;
        @media (max-width: 639px) {
            font-size: 12px;
        }
    }

    .UIInput-error-border {
        border: 1px solid #fff !important;
    }
    margin-bottom: 10px;
}

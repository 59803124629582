* {
    padding: 0px;
    margin: 0px;
    border: 0px;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

:focus,
:active {
    outline: none;
}

a:focus,
a:active {
    outline: none;
}

html,
body {
    height: 100%;
    width: 100%;
}

body {
    color: #000;
    background-color: #F2F2F2;
    font-family: 'Open-sans', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-rendering: optimizeLegibility;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input,
button,
textarea {
    font-family: 'Open-sans', sans-serif;
    font-size: inherit;
}

button {
    cursor: pointer;
    color: inherit;
    background-color: inherit;
}

a {
    color: inherit;
}

a:link,
a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

ul li {
    list-style: none;
}

img {
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: inherit;
    font-size: inherit;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: inherit;
    color: inherit;
}

select::-ms-expand {
    display: none;
}

*::placeholder {
    color: inherit;
}

input::-webkit-calendar-picker-indicator {
    display: none;
}

//  .qrStyle {
//     border: 5px solid rgb(255, 255, 255);
//     height: auto;
//     margin: 0px 10px 5px 0px;
//     max-width: 45%;
// }

// @media (max-width: 640px) {
//     .qrStyle {
//         max-width: 100%;
//     }
    
// }
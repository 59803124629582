@font-face {
    font-family: "Furore";
    src: url('../assets/fonts/Furore.otf');
    src: url('../assets/fonts/Furore.ttf') format("truetype"),
        url('../assets/fonts/Furore.otf');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Audiowide';
    src: url('../assets/fonts/Audiowide-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
